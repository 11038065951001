/**
 * @author  XuHongli
 * @date  2022/11/30 15:42
 * @version 1.0
 * @description 配送方式Map
 */
export const deliveryWayMap = {
  1: {
    text: '货到付款'
  },
  2: {
    text: '在线支付'
  },
}
