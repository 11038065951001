/**
 * @author  XuHongli
 * @date  2022/11/30 15:43
 * @version 1.0
 * @description
 */
export const paymentTypeMap = {
  1: {
    text: '微信支付'
  },
  2: {
    text: '支付宝支付'
  },
  3: {
    text: '余额支付'
  }
}
