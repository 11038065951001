<template>
  <div class="orderTableDetail bg-white p-10">
    <a-spin :spinning="spinning" tip="Loading...">
      <h6>
        {{ `订单号 : ${orderData.id}` }}
        <span
          v-if="(orderData !== null && orderData.refundStatus === null) || orderData.refundStatus === '20'"
          :style="{ color: statusMap[orderData.status].color }"
          class="ml-10"
        >
          {{ orderData.status | statusFilter }}
        </span>
        <span v-else :style="{ color: '#801602' }" class="text-md font-bold my-10">{{
          orderData.refundStatus | afterSaleFilter
        }}</span>
      </h6>
      <!-- 快递单号提交 -->
      <div v-if="orderData.refundStatus == 221">
        <p>
          请在<label style="color: red; font-weight: 600; margin: 0 5px">{{ getImeStatic || '00:00' }}</label
          >内将商品寄回一下地址：{{ orderData.refunds ? orderData.refunds.refundAddress : '' }} ，并提交物流快递单号
        </p>
        <div>
          <el-form :model="courier" label-width="80px">
            <el-row>
              <el-col :span="6">
                <el-form-item label="快递单号">
                  <el-input v-model="courier.refundLogisticsNo" size="small"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="快递公司">
                  <el-select
                    v-model="courier.refundLogisticsName"
                    allow-create
                    clearable
                    filterable
                    placeholder="请选择"
                    size="small"
                  >
                    <el-option
                      v-for="(item, index) in dateCourier"
                      :key="index"
                      :label="item.value"
                      :value="item.value"
                    />
                  </el-select>
                  <el-input v-model="courier.refundLogisticsName" class="company" size="small"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item>
                  <el-button plain size="small" type="primary" @click="SaveClick">确认</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div v-if="Object.keys(orderData).length > 0">
        <!--  标题 商品信息部分  -->

        <el-table
          :data="goodsTableData"
          :header-cell-style="
            () => {
              return 'text-align:center;background:rgb(245, 245, 245)'
            }
          "
          size="small"
          style="width: 100%; border-left: 1px solid #ebeef5; border-right: 1px solid #ebeef5; margin: 20px 0"
        >
          <el-table-column align="center" label="商品图片" prop="picUrl">
            <template slot-scope="scope">
              <img :src="scope.row.picUrl" alt="" style="width: 50px; height: auto" />
            </template>
          </el-table-column>
          <el-table-column align="center" label="商品SPU" prop="productId"> </el-table-column>
          <el-table-column align="center" label="商品名称" prop="name"> </el-table-column>
          <el-table-column align="center" label="商品价格" prop="salesPrice"> </el-table-column>
          <el-table-column align="center" label="数量" prop="quantity"> </el-table-column>
        </el-table>

        <div class="interval my-15"></div>

        <!--   订单信息部分   -->
        <div class="text-md font-bold my-10">订单信息</div>
        <a-descriptions :column="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }" bordered size="small">
          <a-descriptions-item label="订单编号">
            {{ orderData.id }}
          </a-descriptions-item>
          <a-descriptions-item label="支付方式">
            {{ orderData.isPay === '0' ? '未支付' : paymentTypeMapping[orderData.paymentType].text }}
          </a-descriptions-item>
          <a-descriptions-item label="下单时间">
            {{ orderData.createTime }}
          </a-descriptions-item>
          <a-descriptions-item v-if="orderData.status == '2'" label="发货时间">
            {{ orderData.deliveryTime }}
          </a-descriptions-item>
          <a-descriptions-item v-if="orderData.isInvoice === 1" label="下单时间"> 已开票 </a-descriptions-item>
        </a-descriptions>

        <template v-if="orderData.refundStatus != null && orderData.refundStatus != '20'">
          <div class="interval my-15"></div>
          <div class="text-md font-bold my-10">退款信息</div>
          <a-descriptions :column="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }" bordered size="small">
            <a-descriptions-item label="发起时间">
              {{ orderData.refunds.createTime }}
            </a-descriptions-item>
            <a-descriptions-item label="订单状态">
              {{ orderData.refundStatus | afterSaleFilter }}
            </a-descriptions-item>
            <a-descriptions-item label="退款数量">
              {{ amount }}
            </a-descriptions-item>
            <a-descriptions-item label="退款金额">
              {{ orderData.refunds.isRefund == 1 ? '￥' + isTofixed(orderData.refunds.realRefundAmount) : '' }}
            </a-descriptions-item>
            <a-descriptions-item label="是否到账">
              {{ orderData.refunds.isRefund == 1 ? '是（已到账）' : '否（未到账）' }}
            </a-descriptions-item>
            <a-descriptions-item label="退款原因">
              {{ orderData.refunds.refundReson }}
            </a-descriptions-item>
            <a-descriptions-item label="退款备注">
              {{ orderData.refunds.refundRemark }}
            </a-descriptions-item>
            <template
              v-if="
                orderData.refundStatus !== 21 ||
                orderData.refundStatus !== 211 ||
                orderData.refundStatus !== 212 ||
                orderData.refundStatus !== 222
              "
            >
              <a-descriptions-item label="退货地址">
                {{ orderData.refunds.refundAddress }}
              </a-descriptions-item>
              <a-descriptions-item label="订单单号">
                {{ orderData.refunds.refundLogisticsNo }}
              </a-descriptions-item>
              <a-descriptions-item label="物流公司">
                {{ orderData.refunds.logisticsName }}
              </a-descriptions-item>
            </template>
          </a-descriptions>
        </template>

        <div class="interval my-15"></div>

        <div class="text-md font-bold my-10">收货人信息</div>
        <a-descriptions :column="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }" bordered size="small">
          <a-descriptions-item label="收货人姓名">
            {{ orderData.orderLogistics.userName }}
          </a-descriptions-item>
          <a-descriptions-item label="手机号码">
            {{ orderData.orderLogistics.telNum }}
          </a-descriptions-item>
          <!--          <a-descriptions-item label="身份证">-->
          <!--            {{ orderData.deliveryWay | deliveryWayFilter }}-->
          <!--          </a-descriptions-item>-->
          <a-descriptions-item label="地址">
            {{ orderData.orderLogistics.address }}
          </a-descriptions-item>
          <!--          <a-descriptions-item label="原始地址">-->
          <!--            {{ orderData.orderLogistics.address }}-->
          <!--          </a-descriptions-item>-->
        </a-descriptions>

        <div class="interval my-15"></div>
        <div class="text-md font-bold my-10">订单备注</div>
        <div class="font-sm px-5">
          {{ orderData.remark === null || orderData.remark === '' ? '空' : orderData.remark }}
        </div>

        <div class="interval my-15"></div>
        <div class="text-md font-bold my-10">结算信息</div>
        <a-descriptions :column="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }" bordered size="small">
          <a-descriptions-item label="结算金额">
            <span
              >{{ `${isTofixed(orderData.salesPrice)} 元 + 运费 ${isTofixed(orderData.freightPrice)} 元 = ` }}
              <span class="text-danger">{{
                `${(Number(orderData.salesPrice) + Number(orderData.freightPrice)).toFixed(2)} 元`
              }}</span>
            </span>
          </a-descriptions-item>
        </a-descriptions>

        <div class="interval my-15"></div>
        <div v-if="['2', '3'].includes(orderData.status)" >
          <div class="text-md font-bold my-10">
            <div>
              <div style="display: inline-block;margin-right: 10px;">物流信息</div>
              <div style="display: inline-block;font-size: 12px;">
                【
                <span style="margin-right: 15px;">物流公司：{{ orderData.orderLogistics.logistics }}</span>
                <span>物流单号：{{ orderData.orderLogistics.logisticsNo }}</span>
                】
              </div>
            </div>
          </div>
          <div v-if="!orderData.orderLogistics?.details.length">当前订单状态无法查询物流信息</div>
          <a-steps v-else :current="orderData.orderLogistics.details.length" direction="vertical" progress-dot>
            <a-step
              v-for="(item, index) in orderData.orderLogistics.details"
              :key="index"
              :description="item.logisticsTime"
              :title="item.logisticsInformation"
            />
          </a-steps>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/11/30 13:52
 * @version 1.0
 * @description 订单表格内部的订单详情OrderRefundStatusMap
 */
import { OrderStatusMap, OrderRefundStatusMap } from '@/Map/OrderStatusMap'
import { getUserOrderObj, queryLogistics } from '@/api/Order'
import pick from 'lodash.pick'
import { deliveryWayMap } from '@/Map/deliveryWayMap'
import { paymentTypeMap } from '@/Map/paymentTypeMap'
import { prefectRefundLogitics } from '@/api/Order' // 物流订单接口

const aftermarketMap = OrderRefundStatusMap
const statusMap = OrderStatusMap
const deliveryWayMapping = deliveryWayMap
const paymentTypeMapping = paymentTypeMap

export default {
  name: 'OrderTableDetail',
  data() {
    return {
      amount: null,
      // 加载
      spinning: true,
      // 从路由获取的订单ID
      orderId: this.$route.params.orderId,
      // 整个订单数据
      orderData: {},
      // Mapping 映射表
      statusMap,
      paymentTypeMapping,
      deliveryWayMapping,
      // 通过订单数据格式化出来的 商品表格数据
      goodsTableData: [],
      // 物流信息
      logisticsData: null,
      // 查询不到物流的时候的提示
      logisticsTips: '',
      logisticsLength: 0,
      // 快递单号
      courier: {
        refundLogisticsNo: '', //快递单号，
        refundLogisticsName: '', //快递公司
        orderId: this.$route.params.orderId,
      },
      dateCourier: [], //快递公司
      timer: null,
      getImeStatic: '',
    }
  },
  filters: {
    statusFilter(type) {
      // console.log('交易状态文本', type)
      if (type === undefined) {
        return ''
      }
      return statusMap[type].text
    },
    afterSaleFilter(type) {
      if (type === undefined) {
        return ''
      }
      // console.log('退款交易状态文本', type)
      return aftermarketMap[type].text
    },
    deliveryWayFilter(type) {
      // console.log('配送方式', type)
      return deliveryWayMapping[type].text
    },
    paymentTypeFilter(type) {
      // console.log('支付方式', type)
      return paymentTypeMapping[type].text
    },
  },
  created() {
    this.init()
    this.timer = setInterval(() => {
      this.orderData.delayTime--
      this.getIme()
    }, 1000)
  },
  computed: {
    isTofixed (val) {
      return function (val) {
        if (val) {
          return val.toFixed(2)
        }
        return '0.00'
      }
    }
  },
  methods: {
    getIme() {
      var val = this.orderData.delayTime
      console.log('delayTime', val)
      // if(val) return clearInterval(this.timer)
      // 现在的时间戳
      if (val >= 0) {
        let d = parseInt(val / 3600 / 24)
        let h = parseInt((val / 3600) % 24)
        let m = parseInt((val / 60) % 60)
        let s = parseInt(val % 60)
        var time = this.getShi(d) + '天' + this.getShi(h) + '小时' + this.getShi(m) + '分钟' + this.getShi(s) + '秒'
        return (this.getImeStatic = time)
      }
      console.log('到这', val)
      clearInterval(this.timer)
      getUserOrderObj(this.orderId).then((res) => {
        this.amount = res.data.supplyOrderItem.length ? res.data.supplyOrderItem[0].quantity : 1
        this.orderData = res.data
        const fields = ['productId', 'name', 'quantity', 'salesPrice', 'picUrl']
        // 格式化 商品表格数据
        this.goodsTableData = [pick(this.orderData.supplyOrderItem[0], fields)]
      })
    },
    getShi(val) {
      return val < 10 ? '0' + val : val
    },
    // 提交物流信息
    SaveClick() {
      if (this.courier.refundLogisticsNo == '' || this.courier.refundLogisticsName == '') return
      prefectRefundLogitics(this.courier).then((res) => {
        this.$notification.success({
          message: `填写退款物流成功`,
          description: `您已经填写退款物流成功`,
        })
        this.courier.refundLogisticsNo = ''
        this.courier.refundLogisticsName = ''
        this.init()
      })
    },
    // 初始化数据
    init() {
      // 获取订单数据
      let getOrderData = getUserOrderObj(this.orderId).then((res) => {
        this.amount = res.data.supplyOrderItem.length ? res.data.supplyOrderItem[0].quantity : 1
        this.orderData = res.data
        const fields = ['productId', 'name', 'quantity', 'salesPrice', 'picUrl']
        // 格式化 商品表格数据
        this.goodsTableData = [pick(this.orderData.supplyOrderItem[0], fields)]
      })
      // 获取物流数据 '1590248673258029057'
      // let getLogisticsData = queryLogistics(this.orderId).then((res) => {
      //   if (res.data === '当前订单状态无法查询物流信息') {
      //     this.logisticsTips = res.data
      //   } else {
      //     this.logisticsLength =
      //       JSON.parse(res.data).returnCode != 500
      //         ? JSON.parse(res.data).data
      //           ? JSON.parse(res.data).data.length
      //           : 0
      //         : 0
      //     this.logisticsData = JSON.parse(res.data)
      //     // 将原本的数据倒序排序
      //     // if(this.logisticsData.data instanceof Array) {
      //     //   this.logisticsData.data = this.logisticsData.data.reverse()
      //     // }
      //   }
      // })
      Promise.all([getOrderData]).then((res) => {
        this.spinning = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.el-form-item {
  position: relative;
}
.el-form-item .company.el-input {
  position: absolute;
  top: 0px;
  left: 5px;
  width: 80px;
}
.el-form-item .company.el-input__inner {
  color: red;
}
::v-deep .el-form-item .company.el-input input {
  border: none !important;
  height: 28px;
  line-height: 28px;
  padding: 0 15px;
}
.interval {
  width: 100%;
  height: 20px;
  background: rgb(245, 245, 245);
  border-style: solid;
  border-color: rgb(218, 218, 218);
  border-image: initial;
  border-width: 1px 0px;
}
::v-deep .ant-descriptions-item-label {
  width: 200px;
}
::v-deep .ant-descriptions-item-content {
  font-weight: 400;
}
::v-deep .ant-steps-item-content {
  width: 90%;
}
::v-deep .ant-steps-item-active .ant-steps-icon-dot {
  opacity: 0;
}
</style>
